<template>
  <dashboard>
    <el-row id="employer" :gutter="15">
      <el-col :xs="24">
        <div class="back">
          <router-link :to="'/employer/jobs'">
            <a href="#"
               class="el-button el-button--info mb-3">
              <i class="el-icon-back mr-2"/>Back
            </a>
          </router-link>
        </div>
        <div v-if="job" class="jobview">
          <el-card class="employer">
            <div class="header">
              <center>
                <h4 class="orange-text">{{ job.title }}</h4>
                <div class="overview">
                  <center>
                    <h5>
                      <i class="el-icon-money" /> {{ jobPricing(job) }} | <i class="el-icon-timer" /> {{ job.job_type | capitalizeFirst }}
                    </h5>
                  </center>
                </div>
                <!--              <p class="small mt-3 mb-0 gray-text">Date posted: March 03, 2020</p></center>-->
                <p class="small mt-3 mb-0 gray-text">Date posted: {{ job.created_at | usDateFormat }}</p>
              </center>
            </div>
            <div class="body pt-0">
              <div class="apply text-center">
                <template>
                  <el-button :loading="Job.deleting" :disabled="Job.deleting" class="mr-1  gray-text" @click="Job.confirmDelete({ redirect: '/employer/jobs' })">Delete Job</el-button>
                  <router-link :to="`/employer/jobs/${$route.params.job_id}/edit`">
                    <el-button type="warning" class="mr-1">Edit Job</el-button>
                  </router-link>
                  <el-button :loading="Job.deactivating" :disabled="Job.deactivating" type="warning" class="mr-1" plain @click="deactivated ? Job.confirmReactivate() : Job.confirmDeactivate()">{{ deactivated ? 'Re-activate Job' : 'Deactivate Job' }}</el-button>
                </template>
              </div>
              <div class="details">
                <h5 class="orange-text bold mb-3">Overview</h5>
                <div class="intro" v-if="job.intro">
                  <p>{{ job.intro }} </p>
                </div>
                <div class="description">
                  <p>Description:  <span v-html="job.description" /></p>
                </div>
                <hr v-if="skills && skills.length > 0">
                <div v-if="skills && skills.length > 0" class="skills mt-3 mb-4">
                  <h5 class="orange-text bold mb-3">Skills</h5>
                  <el-tag v-for="skill in skills" :key="skill.skill_id ? skill.skill_id : skill.skill_proposal_id" type="info" v-text="skill.skill_name" class="mr-2 mb-2" />
                </div>
              </div>
            </div>
          </el-card>
          <!--Removing applications in job view page-->
          <!-- <div id="applications">
            <h4 v-if="job.applications && job.applications.length > 0" class="orange-text bold mb-3 mt-5">Applicants</h4>
              <div class="freelancer-profile">
              <hr v-if="job.applications && job.applications.length > 0">
              <el-card v-for="application in job.applications" :key="application.application_id">
                <el-row :gutter="15" type="flex">
                  <el-col :span="3">
                    <router-link :to="`/employer/application/${application.application_id}`">
                      <img :src="profilePhotoUrl(application.member)" class="rounded-circle w-100">
                    </router-link>
                  </el-col>
                  <el-col :span="18" class="align-self-center">
                    <router-link :to="`/employer/application/${application.application_id}`">
                      <p class="bold small mb-1" :class="{ blur: application.member.blurred }">{{ application.member.first_name }} {{ application.member.last_name }}</p>
                      
                    </router-link>
                    <p class="small mb-2 mt-1">
                      <span class="bold gray-text">Intro:</span>
                      <span v-text="application.member.profile.intro" />
                    </p>
                    <p class="small mb-2">
                      <span class="bold gray-text">Skills:</span>
                    </p>
                    <skills-list :list="application.member.freelancer_skills" />
                  </el-col>
                  <el-col :span="3" class="text-center text-lg-right">
                    <router-link :to="`/employer/application/${application.application_id}`">
                      <el-button type="warning" size="small" class="" plain circle icon="el-icon-more" />
                    </router-link>
                  </el-col>
                </el-row>
              </el-card>
            </div>
          </div> -->
        </div>
      </el-col>
    </el-row>
  </dashboard>
</template>

<script>
import moment from 'moment'
import Dashboard from '~/components/Dashboard/Dashboard'
import Job from '~/models/Jobs/EmployerJob'
import showsProfilePhoto from '~/mixins/showsProfilePhoto'
import showsJobDetails from '~/mixins/showsJobDetails'
// import SkillsList from '~/components/Skills/List'

export default {
  components: 
  { Dashboard, 
    // SkillsList 
  },

  mixins: [showsProfilePhoto, showsJobDetails],

  filters: {
    usDateFormat (value) {
      return moment(value).format('LL');
    },
  },

  data () {
    return {
      value: 5,
      tempRouteId: this.$route.params.job_id - 10000,
      Job: new Job(this.$route.params.job_id - 10000),
    }
  },

  computed: {
    job () {
      return this.Job.data
    },
    skills () {
      if (this.job.skill && this.job.job_skill_proposals) {
        return [...this.job.skill, ...this.job.job_skill_proposals]
      }
      return []
    },
    deactivated () {
      return this.job.job_status === 'closed'
    },
  },
}
</script>

<style scoped>
</style>

<style lang="scss">
  #employer {
    .jobview .employer .el-card__body{
     padding: 0;
    }
    .jobview .card-body img{
      display: block;
      margin: 15px auto;
      width: 100px;
      height: auto;
    }
    .jobview .employer{
      padding: 0;
    }
  }
  .view-profile {
      text-align: center;
      font-weight: bold;
      padding: 10px;
      background-color: #E87722;
      color: white;
      -webkit-box-shadow: #979797 0 2px 4px 0;
    box-shadow: #979797 0 2px 4px 0;
    border-radius: 0;
    }
</style>
